<template>
  <div class="view-search">
    <div class="header-spacing"></div>

    <div class="container-fluid py-5">
      <div v-if="loadingContent" class="d-flex justify-content-center">
        <loading-spinner />
      </div>
      <div v-else-if="searchResult.length > 0" class="container">
        <h2 class="mr-0 mr-md-3 mb-3 mb-md-0">Resultados da busca</h2>
        <div class="row">
          <div class="col-xl-1-5 col-lg-3 col-md-4 col-6" v-for="(item, index) in searchResult" :key="index">
            <carousel-media :item="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CarouselMedia from '@/components/CarouselMedia'
import { mapState } from 'vuex'

export default {
  components: { CarouselMedia },
  data () {
    return {
      loadingContent: true
    }
  },
  computed: {
    ...mapState('contents', ['searchResult'])
  },
  mounted () {
    const term = this.$route.query.q
    this.$store.dispatch('contents/search', term)
      .finally(() => {
        this.loadingContent = false
      })
  }
}
</script>

<style lang="scss" scoped>
.view-search {
  h2 {
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 21px;
    }
  }
}
</style>
